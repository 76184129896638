.image {
  width: 800;
  height: 170;
  filter: "brightness(70%)";
  border-radius: 8;
  object-fit: "cover";
  object-position: "right top";
}
.img {
  filter: brightness(0.5);
}
