.slider-control-bottomcenter li {
  display: none;
}

.slider-control-centerright,
.slider-control-centerleft {
  font-size: 10px;
}
.slider-control-centerright {
  margin-right: 2.5px;
}
.home-imgs {
  background-image: url("../images/svgType10.gif");

  background-position: center;
  background-size: cover;
}
.home-img1 {
  background-image: url("../images/main.webp");
  height: 100vh;
  width: 100%;
  background-position: center;
  background-size: cover;
  /* background-attachment: fixed; */
  transition: 0.6s;
}

/* .home-img2 {
  background-image: url("https://images.pexels.com/photos/5877662/pexels-photo-5877662.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
  height: 100vh;
  width: 100%;
  background-position: right 0rem;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  transition: 0.6s;
} */

/* @media screen and (max-width: 768px) {
  .home-img2 {
    background-position: bottom left 29%;
  }
} */

.text-container {
  height: 15vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-container h1 {
  margin: 0;
  font-size: 100px;
  color: rgba(225, 225, 225, 0.01);
  background-image: url("https://res.cloudinary.com/dqupmzcrb/image/upload/v1686915869/Untitled-design_odwobp.webp");
  background-repeat: repeat;
  -webkit-background-clip: text;
  animation: animate 15s ease-in-out infinite;
  text-align: center;
  text-transform: uppercase;
  font-weight: 900;
}

@media screen and (max-width: 767px) {
  .home-img1 {
    background-image: url("https://res.cloudinary.com/duk9xkcp5/image/upload/v1692466776/Untitled_uihmo2.webp");
    height: 100%;
    background-position: center;
    background-attachment: local;

    background-color: rgba(0, 0, 0, 33%);
  }
  .text-container {
    height: 5vh;
    margin-top: 6.5rem;
  }
  .text-container h1 {
    font-size: 35px;
    line-height: 1;
    color: wheat;
    background-image: url("https://res.cloudinary.com/dqupmzcrb/image/upload/v1686916563/Untitled_design_222222_lz38ef.png");
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .text-container {
    height: 35vh;
  }
  .text-container h1 {
    font-size: 110px;
    line-height: 1;
  }
}

@keyframes animate {
  0%,
  100% {
    background-position: left top;
  }
  25% {
    background-position: right bottom;
  }
  50% {
    background-position: left bottom;
  }
  75% {
    background-position: right top;
  }
}
