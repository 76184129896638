@keyframes borderAnimation {
  0% {
    border-color: rgb(208, 161, 161);
  }
  25% {
    border-color: rgb(156, 156, 201);
  }
  50% {
    border-color: rgb(154, 192, 154);
  }
  75% {
    border-color: rgb(157, 157, 139);
  }
  100% {
    border-color: rgb(185, 175, 193);
  }
}
.active {
  padding: 10px;
  border-radius: 20px;
  border: 3px solid;
  animation: borderAnimation 2s linear infinite;
}
.headerBtn {
  background-color: #5151c0;
  color: white;
  font-weight: 500;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}
.shadow {
  -webkit-border-radius: 50px;
  border-radius: 50px;
  background: #13338a;
  -webkit-box-shadow: 9px 9px 24px #e2e5f1, -9px -9px 24px #ffffff;
  box-shadow: 9px 9px 24px #c9cbd0, -9px -9px 24px #ffffff;
}

.header {
  height: 100vh;
  width: 50vw;
  overflow: auto;
  background-color: white;
  padding: 20px;
  position: fixed;
  z-index: 1;
  border: 2px solid rgba(128, 128, 128, 0.852);
  -webkit-box-shadow: 9px 9px 24px #e2e5f1, -9px -9px 24px #ffffff;
  box-shadow: 9px 9px 24px #c9cbd0, -9px -9px 24px #ffffff;
  border-radius: ;
}
.cross {
  float: right;
  font-size: 30px;
  color: black;
  cursor: pointer;
  padding: 10px 10px;
}

.slide-right-in {
  -webkit-animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(40px);
    transform: translateX(40px);
  }
  100% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(40px);
    transform: translateX(40px);
  }
  100% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
  }
}

.slide-right-out {
  -webkit-animation: slide-right-out 0.3s ease-out both;

  animation: slide-right-out 0.3s ease-out both;
}

@keyframes slide-right-out {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-110%);
    transform: translateX(-110%);
  }
}

@media only screen and (max-width: 767px) {
  .header {
    height: 100vh;
    width: 100vw;

    position: fixed;
    z-index: 1;
  }
}
