.home-img {
  background-image: url("../images/iron-home.webp");
  height: 100vh;
  width: 100%;
  background-position: center;
  background-size: cover;
  // background-attachment: fixed;
  transition: 0.6s;
}

@media screen and (max-width: 768px) {
  .home-img {
    background-attachment: initial;
  }
}
