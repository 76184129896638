.hotelContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
}

.hotelWrapper {
  width: 100%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.hotelTitle {
  font-size: 24px;
}

.hotelDistance {
  color: #0071c2;
  font-weight: 500;
}

.hotelImages {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 5px;
}

.hotelImgWrapper {
  width: 33%;
}

.hotelImg {
  width: 100%;
  object-fit: cover;
  cursor: pointer;
}

.hotelDetails {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
}

.hotelDetailsTexts {
  flex: 3;
}

.arrow {
  margin: 20px;
  font-size: 40px;
  color: lightgray;
  cursor: pointer;
}
.slider {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.613);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sliderWrapper {
  width: 50%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sliderImg {
  width: 70%;
  height: 50vh;
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: lightgray;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .sliderWrapper {
    width: 70%;
  }
  .sliderImg {
    width: 100%;
    height: 35vh;
  }
  .arrow {
    margin: 10px;
    font-size: 20px;
    color: lightgray;
    cursor: pointer;
  }
}

.react-calendar__month-view__days__day--weekend {
  color: #60a5fa !important;
}

.red-tuesday {
  color: red !important;
  opacity: 0.7;
}
.yellow-date {
  background-color: yellow !important;
  cursor: pointer !important;
}
.disabled-tuesday {
  opacity: 0.5; /* Adjust the styling as per your requirements */
  cursor: no-drop !important  ;
  background-color: red !important;
}
.react-date-picker__calendar-button__icon {
  stroke-width: 1.25;
}
.react-date-picker__clear-button__icon {
  stroke-width: 1.5;
}
.react-date-picker__wrapper {
  border: none !important;
}
.react-date-picker__inputGroup {
  color: black;
}
.react-calendar {
  border: none !important;
  box-shadow: 0 2px 4px lightgray;
}
.react-calendar__navigation {
  color: black;
  border-bottom: thin solid #dee2e5;
}
.react-calendar__navigation button:disabled {
  background-color: white !important;
  color: lightgray;
}
.react-calendar__month-view__weekdays {
  /*color:black;*/
}
.react-calendar__month-view__weekdays__weekday abbr {
  color: black;
  text-decoration: none;
}
.react-calendar__tile:disabled {
  background-color: white;
}
.react-calendar__month-view__days {
  color: black;
  font-size: small;
  background-color: white !important;
}
.react-calendar__tile:disabled {
  background-color: white !important;
  color: lightgray;
}
.react-calendar__month-view__days__day--weekend:enabled {
  color: black !important;
}
.react-calendar__month-view__days__day--weekend:disabled {
  color: lightgray !important;
}
.react-calendar__tile:enabled {
  color: black;
}
.react-calendar__tile--active {
  background: #00ccbb !important;
  color: white !important ;
}

.custom-select {
  position: relative;
}

.custom-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 8px 30px 8px 10px;
  border: none;
  border-radius: 4px;
  background-color: #f5f5f5;
  color: #333;
  font-size: 16px;
  width: 200px;
}

.dropdown-icon {
  position: absolute;
  top: 8px;
  right: 8px;
}

.dropdown-icon > svg {
  margin-left: 5px;
}

@media screen and (max-width: 768px) {
  .resp {
    padding-top: 11px !important;
  }
}

@media screen and (min-width: 769px) {
  .resp {
    padding-top: 18px !important;
  }
}

@media screen and (max-width: 280px) {
  .resp {
    padding-top: 0 !important;
  }
}
