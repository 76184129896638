.greeting-head1 {
  background-color: #ffffff !important;

  max-height: 170px;
  color: #000000 !important;
  --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: 0 9px 23px rgba(0, 0, 0, 0.15), 0 5px 5px rgba(0, 0, 0, 0.22);
  // transition: all 0.01s;
}

.greeting-head2 {
  background-color: transparent;
  padding-top: 1px;
  padding-bottom: 1px;
  max-height: 180px;

  transition: max-height 2.5s ease;
  // transition: background-color 2.5s ease-out;
  // transition: all 0.01s;
}

.mainHead1 {
  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  z-index: 19;
  margin: auto;
}

.greeting-imgs1 {
  max-height: 70px;
  width: auto;
  background-size: cover;
  transition: max-height 0.3s ease;
}

.greeting-imgs2 {
  max-height: 82px;
  width: auto;
  background-size: cover;
  transition: max-height 0.3s ease;
}
.resp-margin {
  height: 140px;
}

@media screen and (max-width: 1064px) {
  .resp-margin {
    height: 85px;
  }
}
@media screen and (max-width: 500px) {
  .resp-margin {
    height: 70px;
  }
}
