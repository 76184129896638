@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@300&family=Comfortaa:wght@300&family=Signika&family=Signika+Negative&display=swap");

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Barlow", sans-serif;
  font-family: "Comfortaa", cursive;
  font-family: "Signika", sans-serif;
  font-family: "Signika Negative", sans-serif;
  scroll-behavior: smooth;
  color: #000000;
  background: #ffffffdf;
  height: max-content;
  max-width: 1920px;
  margin: auto;
}

* {
  box-sizing: border-box;
}

/*This is for grid carousel in home-page */
@media screen and (max-width: 767px) {
  .eedFxX,
  .gWmwOg {
    grid-template-columns: repeat(1, 70%) !important;
  }
}

.card {
  @apply mb-5  block   rounded-lg  bg-slate-100 shadow-custom border-2 border-gray-100;
}
.list {
  @apply mb-2 rounded-lg bg-gray-100  shadow-custom;
}

.default-button {
  @apply rounded bg-gray-200 py-2  px-4 text-black shadow outline-none hover:bg-gray-200  active:bg-gray-300;
}

input,
select {
  @apply rounded border-2 p-1  outline-none border-[#00ccbb] ring-white bg-white text-black focus:ring;
}

input::placeholder {
  color: rgb(146, 148, 152);
  font-size: small;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.dropdown-link {
  @apply flex p-2 hover:bg-gray-200;
}

.alert-error {
  @apply my-3 rounded-lg bg-red-100 p-3 text-red-700;
}

.alert-success {
  @apply my-3 rounded-lg bg-green-100 p-3 text-green-700;
}
.slider-control-centerright,
.slider-control-centerleft {
  display: none;
}

.react-calendar__tile--active {
  background: #1e3a8a;
  color: white;
}
.done {
  font-size: 10px;
  color: white;
  background-color: green;
  padding: 2px;
  width: max-content;
  border-radius: 8px;
}
.not-done {
  font-size: 10px;
  color: white;
  background-color: red;
  padding: 2px;
  width: max-content;
  border-radius: 8px;
}
.top-right {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.primary-button {
  border: none;
  padding: 10px 20px;
  background-color: #5151c0;

  color: white;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
}

.primary-button:hover {
  background-color: #4545a5;
}
.booked {
  color: #ef3515 !important;
}
.danger-button {
  border: none;
  padding: 10px 20px;
  background-color: #ef3515;
  color: white;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
}

.loader {
  width: 58px;
  height: 58px;
  display: inline-block;
  position: relative;
  background-color: #fff;
  background: radial-gradient(
    ellipse at center,
    #00ccbb 0%,
    #00ccbb 14%,
    #fff 15%,
    #fff 100%
  );
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  box-shadow: -1px 1px 10px -1px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 10px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 10px -1px rgba(0, 0, 0, 0.75);
}
.loader::after,
.loader::before {
  content: "";
  position: absolute;
  height: 16px;
  width: 4px;
  background: #00ccbb;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) rotate(0deg);
  transform-origin: 25% 0;
  box-sizing: border-box;
  animation: rotation 10s linear infinite;
}
.loader::before {
  height: 22px;
  width: 2px;
  transform: translateX(-50%) rotate(0deg);
  animation-duration: 1s;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.buttonloader {
  width: 19px;
  height: 19px;
  border: 3px dotted #fff;
  border-style: solid solid dotted dotted;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 767px) {
  .shadow {
    -webkit-border-radius: none;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.159);
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.scale-in-center {
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
.jello-horizontal {
  -webkit-animation: jello-horizontal 1.5s both;
  animation: jello-horizontal 1.5s both;
}

@-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.tilt-in-fwd-tr {
  -webkit-animation: tilt-in-fwd-tr 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: tilt-in-fwd-tr 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes tilt-in-fwd-tr {
  0% {
    -webkit-transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px)
      skew(-35deg, 10deg);
    transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px)
      skew(-35deg, 10deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}
@keyframes tilt-in-fwd-tr {
  0% {
    -webkit-transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px)
      skew(-35deg, 10deg);
    transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px)
      skew(-35deg, 10deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}

.pagination-dots button {
  background-color: #ccc;
  border-radius: 50%;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  height: 10px;
  margin: 0 5px;
  width: 10px;
}

.pagination-dots button.active {
  background-color: #333;
}

.service-loader {
  width: 58px;
  height: 58px;
  background: #ffffff;
  display: inline-block;
  border-radius: 50%;
  box-sizing: border-box;
  animation: animloader 1.5s ease-in-out infinite;
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

/* autocomplete */

.location-search-input {
  font-family: "Lato", sans-serif;
  width: 400px;
  height: 40px;
  background: none;
  border: 2px solid black;
  border-radius: 5px;
  font-size: 1rem;
  color: black;
  font-weight: bold;
}

.input-suggestion {
  font-family: "Lato", sans-serif;
  width: 400px;
  height: auto;
  padding: 5px;
  background-color: white;
  border-bottom: 1px black dotted;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  color: black;
}
@media only screen and (max-width: 767px) {
  .location-search-input,
  .input-suggestion {
    width: 100%;
    height: auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .location-search-input,
  .input-suggestion {
    width: 300px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .location-search-input,
  .input-suggestion {
    width: 300px;
  }
}

.reserve {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.905);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: start;
  justify-content: center;
  z-index: 999;
}

.reserve-admin {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.905);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.highlight:hover {
  transform: scale(1.055);

  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.5s box-shadow,
    0.5s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  cursor: pointer;
}

.rdrCalendarWrapper {
  border: 2px solid gray;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
}

.rel {
  position: relative;
}
.skeleton {
  opacity: 0.7;
  animation: skeleton-loading 0.7s linear infinite alternate;
}

.skeleton-text {
  position: absolute;
  bottom: 2.5rem;
  width: 70%;
  height: 0.5rem;
  margin-bottom: 0.25rem;
  border-radius: 0.125rem;
}

.skeleton-text:last-child {
  position: absolute;
  bottom: 1.4rem;
  width: 30%;
  margin-top: 2rem;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.wobble-hor-bottom {
  -webkit-animation: wobble-hor-bottom 0.8s both;
  animation: wobble-hor-bottom 0.8s both;
}

@-webkit-keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
    transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
    transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
    transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
    transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
    transform: translateX(-6px) rotate(-1.2deg);
  }
}
@keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
    transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
    transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
    transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
    transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
    transform: translateX(-6px) rotate(-1.2deg);
  }
}

.heartbeat {
  -webkit-animation: heartbeat 1.5s ease-in-out both;
  animation: heartbeat 1.5s ease-in-out both;
  color: #00cc00;
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
